import React from 'react';

import { getImageTitleAttributeText, ImageType } from 'common-ui-components/MultiImages/MultiImagesUtils';
import usePersonTooltipSubtitle from 'common-ui-components/MultiImages/MultiPersonsImages/PersonTooltipSubtitleHook';
import NodeImage, { getNodeImageWrapperClass, NodeImageProps } from 'common-ui-components/MultiImages/NodeImage';
import OptionalDirectoryLinkWrapper from 'common-ui-components/MultiImages/OptionalDirectoryLinkWrapper';
import Spinner from 'common-ui-components/Spinner';
import Size from 'global/lists/Size';
import Person from 'model/Person';
import { usePersonDirectoryProfileFullPath } from 'routers/DirectoryRouter/PersonDirectoryUrlHooks';
import usePersonImage from 'screens/platform/cross-platform-components/context/metadata/hooks/PersonImageHook';

import style from 'common-ui-components/MultiImages/style.module.scss';

interface PersonImageProps extends Omit<NodeImageProps, 'imageUrl' | 'size' | 'subtitle'> {
  person: Person;
  size?: Size.SMALL | Size.MEDIUM | Size.BIG;
  hoverable?: boolean;
  subtitle?: string;
}

const DEFAULT_SIZE = Size.MEDIUM;

function PersonImage(props: PersonImageProps) {
  const { hoverable = true } = props;
  const { id: personId, isExternal } = props.person;

  const {
    loading,
  } = usePersonDirectoryProfileFullPath(personId);
  const {
    subtitle,
    loading: subtitleLoading,
  } = usePersonTooltipSubtitle(props.person);

  const image = usePersonImage(personId);
  const size = props.size ?? DEFAULT_SIZE;

  if (loading || subtitleLoading) {
    return (
      <div className={getNodeImageWrapperClass(size)}>
        <Spinner size={Size.SMALL} />
      </div>
    );
  }

  return (
    <OptionalDirectoryLinkWrapper
      to={isExternal ? null : 'platform.directory.people.profile'}
      urlParam={personId}
    >
      <div
        className={style.personImageWrapper}
        title={(hoverable && !isExternal)
          ? getImageTitleAttributeText(ImageType.PEOPLE) : undefined}
      >
        <NodeImage
          {...props}
          subtitle={props.subtitle ?? subtitle}
          size={size}
          imageUrl={image}
        />
      </div>
    </OptionalDirectoryLinkWrapper>
  );
}

const MultiPersonsImagesUtils = { PersonImage };
export default MultiPersonsImagesUtils;
