import { useMemo } from 'react';

import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { TableColumnsOrder } from 'common-ui-components/Table/TableTypes';
import OrganizationFilterableProperty from 'global/api/controller/utils/filtering/OrganizationFilterableProperty';
import {
  OrganizationCustomSortingConfig, sortableOrganizationProperties,
} from 'global/api/controller/utils/OrganizationControllerSortingUtils';
import Metric from 'global/lists/Metric';
import { EnrichedOrganization } from 'model/Organization';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { FiltersConfig } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import useDirectoryTableColumnsHook, {
  DirectoryTableColumn,
} from 'screens/platform/directory/directoryTableColumnsHook';
import { FilterConditionsMap } from 'screens/platform/directory/hooks/filterConditionsHook';
import { useOrganizationsDirectoryContext } from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/context/OrganizationsDirectoryContext';
import useMetricColumns from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/hooks/metricColumnsHook';
import { OrganizationFilterableColumnId } from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationFiltersConfig';
import {
  OrganizationsTableColumnId,
  propertyColumns,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/organizationsTableColumnsConfig';
import OrganizationsTablePropertyColumnId from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationsTablePropertyColumnId';
import OrganizationWithMetrics from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';

export type OrganizationsDirectoryTableColumn = DirectoryTableColumn<
  OrganizationWithMetrics,
  OrganizationsTableColumnId,
  OrganizationCustomSortingConfig
>;

export const defaultSorting: SortingConfig<OrganizationCustomSortingConfig> = {
  columnKey: Metric.ORGANIZATION_LAST_ENGAGEMENT,
  order: 'descend',
  customConfig: { metricType: Metric.ORGANIZATION_LAST_ENGAGEMENT },
};

export const defaultFiltering: FilterConditionsMap<OrganizationFilterableProperty> = {};

export type OrganizationsTableColumnsOrder = TableColumnsOrder<OrganizationsTableColumnId>;

const defaultOrganizationsTableColumns: OrganizationsTableColumnsOrder = {
  [OrganizationsTablePropertyColumnId.NAME]: 0,
  [OrganizationsTablePropertyColumnId.DOMAIN]: 1,
  [OrganizationsTablePropertyColumnId.SEGMENTS]: 2,
  [OrganizationsTablePropertyColumnId.STAGE]: 3,
  [OrganizationsTablePropertyColumnId.CONTACTS]: 4,
  [OrganizationsTablePropertyColumnId.OWNERS]: 5,
  [OrganizationsTablePropertyColumnId.PEOPLE]: 6,
  [Metric.ORGANIZATION_TIME_SINCE_LAST_MENTION]: 7,
  [Metric.ORGANIZATION_LAST_ENGAGEMENT]: 8,
  [Metric.ORGANIZATION_LAST_MEETING]: 9,
};

export function useDefaultOrganizationsTableColumnsConfig(): OrganizationsTableColumnsOrder {
  const { isMetricEnabled } = useMetadataContext();
  return useMemo(
    () =>
      Object.keys(
        defaultOrganizationsTableColumns,
      ).reduce<OrganizationsTableColumnsOrder>((acc, columnKey, index) => {
        if (
          Object.values(Metric).includes(columnKey as Metric)
          && !isMetricEnabled(columnKey as Metric)
        ) {
          return acc;
        }
        acc[columnKey] = index;
        return acc;
      }, {}),
    [isMetricEnabled],
  );
}

export default function useOrganizationsTableColumns(
  filtersConfig?: FiltersConfig<OrganizationFilterableColumnId>,
): {
  columns: OrganizationsDirectoryTableColumn[];
  onColumnMoved: (fromIndex: number, toIndex: number) => void;
  selectedMetrics: Metric[];
  } {
  const metricColumns = useMetricColumns();
  const allColumns = useMemo<OrganizationsDirectoryTableColumn[]>(
    () => [...Object.values(propertyColumns), ...metricColumns],
    [metricColumns],
  );

  const { columnsOrder } = useOrganizationsDirectoryContext();

  const sortableOrganizationColumnsIds = [
    ...sortableOrganizationProperties,
    ...metricColumns.map((c) => c.key),
  ];
  const { columns, onColumnMoved } = useDirectoryTableColumnsHook<
    EnrichedOrganization,
    OrganizationsTableColumnId,
    OrganizationCustomSortingConfig
  >(
    allColumns,
    sortableOrganizationColumnsIds,
    columnsOrder.get(),
    defaultOrganizationsTableColumns,
    columnsOrder.set,
    filtersConfig,
  );

  const selectedMetrics = useMemo<Metric[]>(
    () =>
      columns.reduce((acc, column) => {
        const metric = column.key as Metric;
        if (Object.values(Metric).includes(metric)) {
          acc.push(metric);
        }
        return acc;
      }, [] as Metric[]),
    [columns],
  );

  return { columns, onColumnMoved, selectedMetrics };
}
