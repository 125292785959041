import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Button from 'common-ui-components/Button';
import { MetricValueResponse } from 'global/api/controller/MetricController';
import Organization from 'model/Organization';
import useOrganizationMetricClickHandler
  from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/components/OrganizationMetricButton/organizationMetricClickHandlerHook';
import {
  OrganizationMetric,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';

import style
  from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/components/OrganizationMetricButton/style.module.scss';

type Props = {
  organizationId: Organization['id'];
  metric: OrganizationMetric;
  metricValue?: MetricValueResponse;
  expectedInteractionsCount: any;
  children: ReactNode;
  className?: string;
};

export default function OrganizationMetricButton({
  organizationId,
  metric,
  metricValue,
  expectedInteractionsCount,
  children,
  className,
}: Props) {
  const onClick = useOrganizationMetricClickHandler(
    organizationId,
    metric,
    expectedInteractionsCount,
    metricValue,
  );
  return onClick ? (
    <Button className={classNames(style.button, className)} onClick={onClick}>
      {children}
    </Button>
  ) : (
    <>{children}</>
  );
}
