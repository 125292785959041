import React, { useEffect, useState } from 'react';

import CardContainer from 'common-ui-components/CardContainer';
import Spinner from 'common-ui-components/Spinner';
import SkeletonElement from 'es-src/components/ui/Skeleton/SkeletonElement';
import SkeletonShimmer from 'es-src/components/ui/Skeleton/SkeletonShimmer';
import { isHighConfidence } from 'es-src/screens/HomeScreen/components/CleanPromptSuggestions/CleanPropmtUtils';
import AnswerSessionContent
  from 'es-src/screens/HomeScreen/components/ResultSection/EnterpriseSearchResult/components/AnswerSessionContent';
import SearchResultCard from 'es-src/screens/HomeScreen/components/ResultSection/SearchResultCard';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import { NO_ANSWER } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchFallbackHook';
import useCouldNotFindAnyResults from 'es-src/screens/HomeScreen/hooks/CouldNotFindAnyResultsHook';
import useFoundSearchResultsOnly from 'es-src/screens/HomeScreen/hooks/FoundSearchResultsOnlyHook';
import { TRANSITION_ANIMATION_LENGTH } from 'global/constants';
import ErrorMessage from 'screens/platform/cross-platform-components/ErrorMessage';
import NetworkErrorMessage from 'screens/platform/cross-platform-components/ErrorMessage/NetworkErrorMessage';
import TextLoader from 'screens/platform/cross-platform-components/TextLoader';

import { ReactComponent as SparklesIcon } from 'assets/img/icon/sparkles-icon.svg';

import style from 'es-src/screens/HomeScreen/components/ResultSection/EnterpriseSearchResult/style.module.scss';

export default function EnterpriseSearchResult() {
  const {
    loading, data, originalQuestion, hasError,
  } = useEnterpriseSearchContext();
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (loading) {
      timeoutId = setTimeout(() => {
        setShowSkeleton(true);
      }, TRANSITION_ANIMATION_LENGTH * 2);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading]);

  const couldNotFindAnyResults = useCouldNotFindAnyResults();
  const hasFoundSearchResultsOnly = useFoundSearchResultsOnly();

  if (loading && showSkeleton) return <EnterpriseSearchResultSkeleton />;

  if (data === null && !hasError) return null;

  const title = data?.cleanedPromptResult
    && isHighConfidence(data.cleanedPromptResult.confidenceScore)
    ? data.cleanedPromptResult.cleanedPrompt
    : originalQuestion;

  const isTask = data !== null && data.intent === 'TASK';

  const shouldRenderContent = !isTask
    && !hasError && data !== null && data.answer !== NO_ANSWER;

  const collapsed = !couldNotFindAnyResults
    && data !== null && data.intent === 'SEARCH';

  return (
    <SearchResultCard
      icon={SparklesIcon}
      title={title}
      hasError={hasError !== null || couldNotFindAnyResults || hasFoundSearchResultsOnly}
      collapsed={collapsed}
      collapsedText="Show AI Answer ↓"
    >
      {
        shouldRenderContent && (
          <AnswerSessionContent
            data={data}
          />
        )
      }
      {hasError === 'network' && <NetworkErrorMessage />}
      {hasError === 'abort' && <ErrorMessage heading="Whoops! You cancelled. Maybe try a different question?" />}
      {
        isTask && (
          <ErrorMessage
            heading="We don't support tasks -- yet!"
            subtitle="Try asking a question, or look at the search results below"
          />
        )
      }
      {
        !isTask && couldNotFindAnyResults && (
          <ErrorMessage
            heading="We couldn't find any results that match your criteria"
            subtitle="Try searching for something else, or have a look at the suggested questions below"
          />
        )
      }
      {
        !isTask && hasFoundSearchResultsOnly
          && <ErrorMessage emoji="👇" heading="We couldn't find a definite answer" subtitle="But we did find several relevant search results below" />
      }
    </SearchResultCard>
  );
}

function EnterpriseSearchResultSkeleton() {
  return (
    <CardContainer className={style.skeleton}>
      <div className={style.searchResultContent} data-testid="es-result-skeleton">
        <div className={style.spinnerContainer} data-testid="es-result-spinner">
          <Spinner />
        </div>
        <div className={style.resultDetails}>
          <div className={style.skeletonColumContainer}>
            <TextLoader enterpriseSearch />
            <SkeletonElement type="paragraph" />
          </div>
          <div className={style.skeletonColumContainer}>
            <SkeletonElement type="subtitle" />
            <div className={style.skeletonRowContainer}>
              <SkeletonElement type="badge" />
              <SkeletonElement type="badge" />
              <SkeletonElement type="badge" />
            </div>
          </div>
          <SkeletonElement type="subtitle" />
        </div>
        <SkeletonShimmer />
      </div>
    </CardContainer>
  );
}
