import React from 'react';

import TagBadge from 'common-ui-components/Badge/TagBadge';
import ExpandableCell from 'common-ui-components/Table/ExpandableCell';
import { Atom } from 'model/Atom';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

type Props = {
  atom: Atom;
  topicsOnly?: boolean;
};

export default function InteractionTags({ atom, topicsOnly = false }: Props) {
  const { isTagTopic } = useMetadataContext();
  const tags = topicsOnly ? Object.keys(atom.tags).filter(isTagTopic) : Object.keys(atom.tags);
  return tags.length > 0 ? (
    <ExpandableCell>
      {tags.map((tag) => (
        <TagBadge key={tag} tag={tag} externalLink={isTagTopic(tag) ? undefined : atom.tags[tag]} />
      ))}
    </ExpandableCell>
  ) : null;
}
