import { useMemo } from 'react';

import Metric from 'global/lists/Metric';
import { EnrichedOrganization } from 'model/Organization';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useOrganizationMetricsPopulator
  from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/hooks/organizationsMetricPopulatorHook';

export default function useOrganizationLastEngagement(
  organization: EnrichedOrganization,
): string | undefined {
  const { isMetricEnabled } = useMetadataContext();

  const orgWithMetrics = useOrganizationMetricsPopulator(
    [Metric.ORGANIZATION_LAST_ENGAGEMENT].filter(isMetricEnabled),
    useMemo(() => organization, [organization]),
  );

  return orgWithMetrics?.metricValues?.ORGANIZATION_LAST_ENGAGEMENT?.interaction?.end;
}
