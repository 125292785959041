import classNames from 'classnames';
import React from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import ExternalLink from 'common-ui-components/ExternalLink';
import InteractionTitle from 'common-ui-components/InteractionTitle';
import Size from 'global/lists/Size';
import { Atom } from 'model/Atom';

import { ReactComponent as ExternalLinkIcon } from 'assets/img/icon/external-link.svg';

import style from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/style.module.scss';

interface Props {
  atom: Atom;
  shouldShowParticipants?: boolean;
  transparent?: boolean;
}

export default function AtomSearchResult({
  atom, shouldShowParticipants = false, transparent = false,
}: Props) {
  const {
    appId, permalink, isPublic, mimeType,
  } = atom;

  return (
    <ExternalLink
      to={permalink}
      className={classNames(style.atomSearchResult, transparent && style.transparent)}
    >
      <AppIcon
        appId={appId}
        isPublic={isPublic}
        hasBorder={false}
        size={Size.MEDIUM}
        mimeType={mimeType}
      />
      <InteractionTitle
        atom={atom}
        options={{
          shouldShowSubtitle: true,
          showUpdatedAt: true,
          enterpriseSearchDesign: true,
          shouldShowParticipants,
          hasTooltip: true,
        }}
      />
      <ExternalLinkIcon className={style.externalLinkIcon} />
    </ExternalLink>
  );
}
