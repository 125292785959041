import React, { forwardRef, MouseEventHandler, ReactNode } from 'react';

interface Props {
  to: string;
  className?: string;
  title?: string;
  children: ReactNode;
  onClick?: MouseEventHandler;
  download?: boolean;
}

const ExternalLink = forwardRef<HTMLAnchorElement, Props>(
  ({
    to, className, title, children, onClick, download = false,
  }: Props, ref) => (
    <a
      ref={ref}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      className={className}
      title={title}
      download={download}
    >
      {children}
    </a>
  ),
);

export default ExternalLink;
