import classNames from 'classnames';
import React from 'react';

import PersonProfileImage from 'common-ui-components/MultiImages/ProfileImage/PersonProfileImage';
import OptionalButton from 'common-ui-components/OptionalButton';
import Spinner from 'common-ui-components/Spinner';
import Size from 'global/lists/Size';
import { useInternalScreenRedirect } from 'global/ScreensConfiguration';
import Person from 'model/Person';
import useAsync from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import DebuggerConsole from 'utils/DebuggerConsole';

import style from 'screens/platform/cross-platform-components/PersonBadge/style.module.scss';

interface Props {
  personId: Person['id'];
  size?: Size;
  hasDepartment?: boolean;
  hasBorder?: boolean;
  className?: string;
  openNewTab?: boolean;
  clickable?: boolean;
  onLiner?: boolean;
}

export default function PersonBadge({
  personId,
  size = Size.SMALL,
  hasDepartment = false,
  hasBorder = false,
  openNewTab = false,
  onLiner = false,
  className,
  clickable,
}: Props) {
  const { persons } = useMetadataContext();
  const redirectToInternalScreen = useInternalScreenRedirect(openNewTab);

  const { value: person, loading } = useAsync(
    () => persons.getById(personId),
    [personId],
  );

  function handleClick() {
    redirectToInternalScreen(
      'platform.directory.people.profile',
      personId,
    );
  }

  if (loading) return <Spinner />;

  if (!person) {
    DebuggerConsole.error('Failed to retrieve person information for person', personId);
    return null;
  }

  return (
    <OptionalButton
      className={classNames(
        style.personWithImageContainer,
        hasBorder && style.border,
        size === Size.MEDIUM && style.medium,
        onLiner && style.oneLiner,
        className,
      )}
      onClick={person.isExternal || !clickable ? undefined : handleClick}
      transparent={!hasBorder}
      color={hasBorder ? 'gray' : undefined}
      title="Go to person's page"
      testId="person-badge"
    >
      <div className={style.image}>
        <PersonProfileImage person={person} size={size} />
      </div>
      <div className={style.details}>
        <span className={style.name}>{person.name}</span>
        {onLiner ? <span className={style.bullet}>•</span> : null}
        {hasDepartment ? (
          <span className={style.department}>
            {person.department}
          </span>
        ) : null}
      </div>
    </OptionalButton>
  );
}
