import { useMemo } from 'react';

import subDays from 'date-fns/subDays';
import Person from 'model/Person';
import Tenant from 'model/Tenant';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import HierarchicalMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/HierarchicalMasterFilter';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import PeopleMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter';
import {
  MetadataContextType,
  useMetadataContext,
} from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import {
  SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import { endOfUTCDay, startOfUTCDay } from 'utils/DateUtils';

export const DEFAULT_ALL_COMPANY_ID = 'all_company';
export enum DefaultFilterLabels {
  ALL_COMPANY = 'All People',
  ALL_TAGS = 'All Tags',
  ALL_APPS = 'All Apps',
  ALL_ORGANIZATIONS = 'All Organizations',
}

export function getDefaultPeople(): MasterFilters['people'] {
  return PeopleMasterFilter.generateAllInternalSelected(false);
}

export function getDefaultOrganizations(): MasterFilters['organizations'] {
  return OrganizationsMasterFilter.generateAllSelected();
}

export function getDefaultCategories(
  categories: Record<string, string[]>,
): MasterFilters['categories'] {
  return {
    tagsByCategories: new HierarchicalMasterFilter(DefaultFilterLabels.ALL_TAGS, categories, true),
    includeUntagged: false,
  };
}

function getDefaultApps(
  appsToChannelsMap: Readonly<Partial<Record<string, string[]>>>,
): MasterFilters['apps'] {
  const appsState = new HierarchicalMasterFilter(
    DefaultFilterLabels.ALL_APPS,
    appsToChannelsMap,
    true,
  );
  appsState.getChild(SlackSubDivisionId.SLACK_BOT_CHANNELS)?.deselect();
  return appsState;
}

function getDefaultDatesRange(): MasterFilters['datesRange'] {
  const from = startOfUTCDay(subDays(new Date(), 27));
  const to = endOfUTCDay(new Date());

  return { from, to };
}

export function getDefaultDirectionality(): MasterFilters['directionality'] {
  return [Directionality.INTERNAL];
}

function getDefaultMasterFilters(
  metadataContext: MetadataContextType,
  tenantId: Tenant['id'],
  userId: Person['id'] | null,
): MasterFilters {
  const {
    channels, categories,
  } = metadataContext;

  return {
    id: DEFAULT_ALL_COMPANY_ID,
    name: DefaultFilterLabels.ALL_COMPANY,
    categories: getDefaultCategories(categories),
    apps: getDefaultApps(channels.appsToChannelsMap),
    datesRange: getDefaultDatesRange(),
    people: getDefaultPeople(),
    organizations: getDefaultOrganizations(),
    directionality: getDefaultDirectionality(),
    tenantId,
    creatorPersonId: userId || '',
    version: 0,
  };
}

export function useDefaultMasterFilters(): Readonly<MasterFilters> {
  const metadataContext = useMetadataContext();
  const { tenant } = useTenantContext();
  const { personId } = useUserContext();

  return useMemo(
    () => getDefaultMasterFilters(
      metadataContext,
      tenant.id,
      personId,
    ),
    [tenant.id],
  );
}
