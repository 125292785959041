import React from 'react';

import MultiOrganizationsImages from 'common-ui-components/MultiImages/MultiOrganizationsImages';
import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';
import MultiTagsBadges from 'common-ui-components/MultiTagsBadges';
import SpacedBullet from 'common-ui-components/SpacedBullet';

import style from 'screens/platform/cross-platform-components/InteractionDetails/style.module.scss';

interface Props {
  tags: string[];
  organizations: string[];
  participantsIds: string[];
}

const NON_BREAKING_SPACE = '\u00A0';

export default function InteractionDetailsFooter({ tags, organizations, participantsIds }: Props) {
  const hasTag = tags.length > 0;
  const hasOrganization = organizations.length > 0;
  const hasParticipants = participantsIds.length > 0;

  const elements = [
    hasTag ? <MultiTagsBadges tags={[tags[0]]} /> : null,
    hasOrganization ? <MultiOrganizationsImages ids={[organizations[0]]} /> : null,
    hasParticipants ? <MultiPersonsImages ids={participantsIds} maxImagesThreshold={3} /> : null,
  ].reduce<(() => JSX.Element)[]>((elementsToRender, element) => {
    if (element !== null) {
      elementsToRender.push(() => element);
      elementsToRender.push(() => <SpacedBullet />);
    }
    return elementsToRender;
  }, []);
  elements.pop(); // Remove last SpacedBullet

  return (
    <div className={style.footer}>
      {elements.length > 0
        ? elements.map((Component) => <Component key={crypto.randomUUID()} />)
        : NON_BREAKING_SPACE}
    </div>
  );
}
