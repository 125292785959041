import React, { ReactNode } from 'react';

import InternalLink from 'common-ui-components/InternalLink/index';
import { ScreenKey } from 'global/ScreensConfiguration';

interface Props {
  to: ScreenKey | null;
  urlParam?: string;
  children: ReactNode;
}

export default function OptionalDirectoryLinkWrapper({ to, urlParam, children }: Props) {
  if (to === null) return <>{children}</>;
  return (
    <InternalLink to={to} urlParam={urlParam}>
      {children}
    </InternalLink>
  );
}
