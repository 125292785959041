import React from 'react';

import Button from 'common-ui-components/Button';
import ExternalLink from 'common-ui-components/ExternalLink';
import { useInternalScreenRedirect } from 'global/ScreensConfiguration';
import { TagColors } from 'screens/platform/cross-platform-components/context/metadata/hooks/TagsColorsHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import { InteractionsTableState } from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';

import style from 'common-ui-components/Badge/TagBadge/tagBadge.module.scss';

export interface TagBadgeProps {
  tag: string;
  colors?: TagColors;
  clickable?: boolean;
  openNewTab?: boolean;
  externalLink?: string;
}

export default function TagBadge({
  tag,
  colors,
  clickable = true,
  openNewTab = false,
  externalLink,
}: TagBadgeProps) {
  const { getTagColors, isTagTopic } = useMetadataContext();
  const { interactionsTable } = usePlatformContext();
  const redirectToInternalScreen = useInternalScreenRedirect(openNewTab);

  const tagBadge = (
    <div className={style.badgeContainer} style={colors ?? getTagColors(tag)}>
      {tag}
    </div>
  );

  const onTagLinkClick = () => {
    if (interactionsTable.openState !== InteractionsTableState.CLOSED) {
      interactionsTable.setOpenState(InteractionsTableState.CLOSED, null);
    }
    redirectToInternalScreen(
      'platform.directory.topics.profile',
      tag,
    );
  };

  if (externalLink) {
    return (
      <ExternalLink title="Go to tag occurrence" to={externalLink}>
        {tagBadge}
      </ExternalLink>
    );
  }

  return clickable && isTagTopic(tag) ? (
    <Button
      onClick={onTagLinkClick}
      title="Go to topic profile"
      className={style.badgeButton}
    >
      {tagBadge}
    </Button>
  ) : (
    tagBadge
  );
}
