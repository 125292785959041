enum OrganizationsTablePropertyColumnId {
  NAME = 'NAME',
  DOMAIN = 'DOMAIN',
  CONTACTS = 'CONTACTS',
  PEOPLE = 'PEOPLE',
  SIZE = 'SIZE',
  RISK = 'RISK',
  OWNERS = 'OWNERS',
  SEGMENTS = 'SEGMENTS',
  STAGE = 'STAGE',
  LOCATION = 'LOCATION',
  MODIFIED_AT = 'MODIFIED_AT',
  CLOSED_AT = 'CLOSED_AT',
  RENEWED_AT = 'RENEWED_AT',
}

export default OrganizationsTablePropertyColumnId;
