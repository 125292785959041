import classNames from 'classnames';
import React, { Dispatch, useEffect, useRef } from 'react';

import TagBadge from 'common-ui-components/Badge/TagBadge';
import Dropdown from 'common-ui-components/Dropdown';
import useKeyboardNavigation from 'common-ui-components/Dropdown/keyboardNavigationHook';
import Spinner from 'common-ui-components/Spinner';
import SearchInput from 'es-src/screens/HomeScreen/components/EnterpriseSearch/SearchInput';
import AutocompleteResultCategory
  from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory';
import OrganizationResult from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/OrganizationResult';
import PersonResult from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/PersonResult';
import HintMessage from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/HintMessage';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import { AutoCompleteResponse } from 'global/api/controller/EnterpriseSearchController';
import Api from 'global/api/platformApi';
import HttpStatus from 'global/lists/HttpStatus';
import getScreenConfig, { useInternalScreenRedirect } from 'global/ScreensConfiguration';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useDebounce, useMountedState } from 'utils/hooks';

import autoCompleteStyle
  from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/style.module.scss';
import style from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/style.module.scss';

interface Props {
  searchQuery: string;
  setSearchQuery: Dispatch<React.SetStateAction<string>>;
  customSearchHandler?: () => void;
  compact?: boolean;
}

const MIN_AUTOCOMPLETE_CHARS = 3;
const AUTOCOMPLETE_DEBOUNCE = 250;

export default function SearchWithAutocomplete({
  searchQuery,
  setSearchQuery,
  customSearchHandler,
  compact,
}: Props) {
  const { tenant } = useTenantContext();
  const { getTagColors } = useMetadataContext();
  const { loading: isEnterpriseSearchLoading, originalQuestion } = useEnterpriseSearchContext();
  const [options, setOptions] = useMountedState<AutoCompleteResponse | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useMountedState(false);
  const [isEntityClicked, setIsEntityClicked] = useMountedState(false);
  const [isLoading, setIsLoading] = useMountedState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedQuery = useDebounce(searchQuery, AUTOCOMPLETE_DEBOUNCE);
  const redirectToInternalScreen = useInternalScreenRedirect();

  const highlightedElementIndex = useKeyboardNavigation({
    isDropdownOpen,
    menuElement: containerRef,
    closeDropdown: () => setIsDropdownOpen(false),
    selectedOptionClassName: autoCompleteStyle.highlightedOption,
    inputRef,
    resetDependencies: [options],
  });

  useEffect(() => {
    const handleAutoComplete = async () => {
      if (debouncedQuery.length < MIN_AUTOCOMPLETE_CHARS
        || isEnterpriseSearchLoading || debouncedQuery === originalQuestion) {
        setIsEntityClicked(false);
        setIsDropdownOpen(false);
        setOptions(null);
        return;
      }

      try {
        setIsLoading(true);
        setOptions(null);
        setIsEntityClicked(false);

        const searchResponse = await Api.EnterpriseSearch.getAutoComplete(tenant.id, searchQuery);

        if (searchResponse?.status === HttpStatus.OK) {
          setOptions(searchResponse.data);
          const hasResults = [
            searchResponse.data.people.totalResultsCount,
            searchResponse.data.topics.totalResultsCount,
            searchResponse.data.organizations.totalResultsCount,
          ].some((count) => count > 0);
          setIsDropdownOpen(hasResults);
        }
      } catch (err) {
        DebuggerConsole.error('Failed to fetch autocomplete', err);
        setOptions(null);
        setIsDropdownOpen(false);
      } finally {
        setIsLoading(false);
      }
    };

    handleAutoComplete();
  }, [debouncedQuery, isEnterpriseSearchLoading, tenant.id]);

  const handleResultClick = (
    entityType: 'people' | 'topics' | 'organizations',
    entityId: string | undefined,
    entityName: string,
  ) => {
    if (!entityId) {
      DebuggerConsole.error("EntityId doesn't exist", { entityType, entityId, entityName });
      return;
    }

    setIsDropdownOpen(false);
    setIsEntityClicked(true);
    setSearchQuery('');
    redirectToInternalScreen(`platform.directory.${entityType}.profile`, entityId);

    const { path: navigatedToLink } = getScreenConfig(`platform.directory.${entityType}.profile`, entityId);
    Analytics.trackEvent(AnalyticsEvent.NAVIGATE_FROM_SEARCHBOX, {
      searchBoxInput: searchQuery,
      navigatedToName: entityName,
      navigatedToType: entityType,
      navigatedToLink,
    });
  };

  const {
    topics: { results: topicsResults = [], totalResultsCount: totalTopicsCount = 0 } = {},
    organizations: { results: orgResults = [], totalResultsCount: totalOrgCount = 0 } = {},
    people: { results: peopleResults = [], totalResultsCount: totalPeopleResultCount = 0 } = {},
  } = options ?? {};

  const isHintVisible = highlightedElementIndex === null;

  return (
    <Dropdown
      isDropdownOpen={isDropdownOpen}
      closeDropdown={() => setIsDropdownOpen(false)}
      placement="bottom-start"
      popperOptions={{ distance: 0 }}
      dropdownClassName={classNames(style.dropdownClassName, isDropdownOpen && style.dropdownOpen)}
      toggleButtonElement={(
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder="Ask anything"
          autofocus
          customInputRef={inputRef}
          isDropdownOpen={isDropdownOpen}
          customSearchHandler={customSearchHandler}
          compact={compact}
          isEntityClicked={isEntityClicked}
        />
      )}
    >
      <div ref={containerRef}>
        {isLoading && !isEnterpriseSearchLoading && <Spinner />}
        {!isLoading && !isEnterpriseSearchLoading && options && (
          <>
            <div className={isHintVisible ? style.lastSection : undefined}>
              {totalTopicsCount > 0 && (
              <AutocompleteResultCategory
                entityType="topics"
                entities={topicsResults}
                entitiesCount={totalTopicsCount}
                handleClick={(tag) => handleResultClick('topics', tag.value, tag.value)}
                renderChild={(tag) =>
                  <TagBadge tag={tag.value} colors={getTagColors(tag.value)} clickable={false} />}
              />
              )}

              {totalOrgCount > 0 && (
              <>
                {totalTopicsCount > 0 && <div className={style.separator} />}

                <AutocompleteResultCategory
                  entityType="organizations"
                  entities={orgResults}
                  entitiesCount={totalOrgCount}
                  handleClick={(org) => handleResultClick('organizations', org.id, org.id)}
                  renderChild={(org) => <OrganizationResult organization={org} />}
                />
              </>
              )}

              {totalPeopleResultCount > 0 && (
              <>
                {(totalTopicsCount > 0 || totalOrgCount > 0) && <div className={style.separator} />}

                <AutocompleteResultCategory
                  entityType="people"
                  entities={peopleResults}
                  entitiesCount={totalPeopleResultCount}
                  handleClick={(person) => handleResultClick('people', person._id, person.name)}
                  renderChild={(person) => <PersonResult person={person} />}
                />
              </>
              )}
            </div>
            <HintMessage isVisible={isHintVisible} />
          </>
        )}
      </div>
    </Dropdown>
  );
}
