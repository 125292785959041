import React from 'react';

import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';
import OrganizationDomain from 'common-ui-components/OrganizationDomain';
import { OrganizationPropertySorting } from 'global/api/controller/utils/OrganizationControllerSortingUtils';
import Metric, { getMetricDisplayName } from 'global/lists/Metric';
import Size from 'global/lists/Size';
import { DirectoryTableColumn } from 'screens/platform/directory/directoryTableColumnsHook';
import OrganizationName from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/components/OrganizationName';
import OrganizationOwnersImages from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/components/OrganizationOwnersImages';
import OrganizationsTablePropertyColumnId from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationsTablePropertyColumnId';
import OrganizationWithMetrics, {
  OrganizationMetric,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';
import {
  organizationRiskToDisplayString,
  organizationSizeToDisplayString,
} from 'screens/settings/OrganizationsScreen/components/OrganizationsTable/OrganizationUtils';
import DateUtils from 'utils/DateUtils';

export type OrganizationsTableColumnId =
  | OrganizationsTablePropertyColumnId
  | OrganizationMetric;

export function isOrganizationsTableCommonColumnId(
  id: string,
): id is OrganizationsTablePropertyColumnId {
  return id in OrganizationsTablePropertyColumnId;
}

const columnLabels: Record<OrganizationsTablePropertyColumnId, string> = {
  [OrganizationsTablePropertyColumnId.NAME]: 'Name',
  [OrganizationsTablePropertyColumnId.DOMAIN]: 'Domain',
  [OrganizationsTablePropertyColumnId.CONTACTS]: 'Contacts',
  [OrganizationsTablePropertyColumnId.PEOPLE]: 'People',
  [OrganizationsTablePropertyColumnId.SIZE]: 'Size',
  [OrganizationsTablePropertyColumnId.RISK]: 'Risk',
  [OrganizationsTablePropertyColumnId.OWNERS]: 'Owners',
  [OrganizationsTablePropertyColumnId.SEGMENTS]: 'Segments',
  [OrganizationsTablePropertyColumnId.STAGE]: 'Stage',
  [OrganizationsTablePropertyColumnId.LOCATION]: 'Location',
  [OrganizationsTablePropertyColumnId.MODIFIED_AT]: 'Modified At',
  [OrganizationsTablePropertyColumnId.CLOSED_AT]: 'Closed At',
  [OrganizationsTablePropertyColumnId.RENEWED_AT]: 'Renewed At',
};

export function getOrganizationsTableColumnLabel(columnId: string): string {
  if (isOrganizationsTableCommonColumnId(columnId)) {
    return columnLabels[columnId];
  }
  return getMetricDisplayName(columnId as Metric);
}

const formatDateColumnValue = (dateString?: string) =>
  (dateString ? DateUtils.getFormattedDate(dateString) : '-');

type OrganizationTablePropertyColumn = DirectoryTableColumn<
  OrganizationWithMetrics,
  OrganizationsTableColumnId,
  OrganizationPropertySorting
>;

export const propertyColumns: Record<
  OrganizationsTablePropertyColumnId,
  OrganizationTablePropertyColumn
> = {
  [OrganizationsTablePropertyColumnId.NAME]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.NAME,
    ),
    key: OrganizationsTablePropertyColumnId.NAME,
    customSortingConfig: {
      organizationProperty: OrganizationsTablePropertyColumnId.NAME,
    },
    render: (_, organization) => (
      <OrganizationName organization={organization} />
    ),
  },
  [OrganizationsTablePropertyColumnId.DOMAIN]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.DOMAIN,
    ),
    key: OrganizationsTablePropertyColumnId.DOMAIN,
    customSortingConfig: {
      organizationProperty: OrganizationsTablePropertyColumnId.DOMAIN,
    },
    render: (_, organization) => (
      <OrganizationDomain organization={organization} />
    ),
  },
  [OrganizationsTablePropertyColumnId.CONTACTS]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.CONTACTS,
    ),
    key: OrganizationsTablePropertyColumnId.CONTACTS,
    render: (_, organization) => (
      <MultiPersonsImages ids={organization.contacts} size={Size.BIG} />
    ),
  },
  [OrganizationsTablePropertyColumnId.PEOPLE]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.PEOPLE,
    ),
    key: OrganizationsTablePropertyColumnId.PEOPLE,
    render: (_, organization) => (
      <MultiPersonsImages ids={organization.topInternalPeople} size={Size.BIG} />
    ),
  },
  [OrganizationsTablePropertyColumnId.SIZE]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.SIZE,
    ),
    key: OrganizationsTablePropertyColumnId.SIZE,
    customSortingConfig: {
      organizationProperty: OrganizationsTablePropertyColumnId.SIZE,
    },
    render: (_, { properties }) => (
      <>{organizationSizeToDisplayString(properties?.size)}</>
    ),
  },
  [OrganizationsTablePropertyColumnId.RISK]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.RISK,
    ),
    key: OrganizationsTablePropertyColumnId.RISK,
    customSortingConfig: {
      organizationProperty: OrganizationsTablePropertyColumnId.RISK,
    },
    render: (_, { properties }) => (
      <>{organizationRiskToDisplayString(properties?.risk)}</>
    ),
  },
  [OrganizationsTablePropertyColumnId.OWNERS]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.OWNERS,
    ),
    key: OrganizationsTablePropertyColumnId.OWNERS,
    render: (_, organization) => (
      <OrganizationOwnersImages organization={organization} />
    ),
  },
  [OrganizationsTablePropertyColumnId.SEGMENTS]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.SEGMENTS,
    ),
    key: OrganizationsTablePropertyColumnId.SEGMENTS,
    render: (_, { properties }) =>
      (properties?.segments && properties.segments.length > 0
        ? properties.segments.join(', ')
        : '-'),
  },
  [OrganizationsTablePropertyColumnId.STAGE]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.STAGE,
    ),
    key: OrganizationsTablePropertyColumnId.STAGE,
    render: (_, { properties }) => properties?.stage || '-',
  },
  [OrganizationsTablePropertyColumnId.LOCATION]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.LOCATION,
    ),
    key: OrganizationsTablePropertyColumnId.LOCATION,
    render: (_, { properties }) => properties?.location || '-',
  },
  [OrganizationsTablePropertyColumnId.CLOSED_AT]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.CLOSED_AT,
    ),
    key: OrganizationsTablePropertyColumnId.CLOSED_AT,
    render: (_, { properties }) => formatDateColumnValue(properties?.closedAt),
  },
  [OrganizationsTablePropertyColumnId.MODIFIED_AT]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.MODIFIED_AT,
    ),
    key: OrganizationsTablePropertyColumnId.MODIFIED_AT,
    render: (_, { properties }) =>
      formatDateColumnValue(properties?.modifiedAt),
  },
  [OrganizationsTablePropertyColumnId.RENEWED_AT]: {
    title: getOrganizationsTableColumnLabel(
      OrganizationsTablePropertyColumnId.RENEWED_AT,
    ),
    key: OrganizationsTablePropertyColumnId.RENEWED_AT,
    render: (_, { properties }) => formatDateColumnValue(properties?.renewedAt),
  },
};
