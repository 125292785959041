import {
  RefObject, useEffect, useRef, useState,
} from 'react';

import { useKeystrokesListener } from 'utils/hooks';

const MIN_INDEX = 1;
export const KEYBOARD_NAVIGATION_OPTION_CLASS_NAME = 'option';

function getInitialIndex(automaticallySelectFirstOption: boolean): 1 | null {
  return automaticallySelectFirstOption ? MIN_INDEX : null;
}

interface Props {
  isDropdownOpen: boolean;
  menuElement: RefObject<HTMLDivElement>;
  closeDropdown: () => void;
  selectedOptionClassName: string;
  inputRef: RefObject<HTMLInputElement>;
  resetDependencies?: any[];
  automaticallySelectFirstOption?: boolean;
  onPressEnter?: (index: number) => void;
}

/**
 * Adds keyboard navigation to elements in a dropdown. The option elements must have
 * KEYBOARD_NAVIGATION_OPTION_CLASS_NAME to enable this functionality.
 * @param {Object} props - Configuration for the hook
 * @param props.isDropdownOpen - Whether the dropdown is open
 * @param props.menuElement - A reference to the element that wraps all the options
 * @param props.closeDropdown - A callback that closes the dropdown (being called when
 * pressing Escape)
 * @param props.selectedOptionClassName - A class that describes the selected option
 * @param props.resetDependencies - The dependency array to reset the index of the selected option
 * @param props.automaticallySelectFirstOption - determines whether the first option in the dropdown
 * is highlighted by default
 * @param props.inputRef - the input element that is used to trigger the dropdown
 * @param props.onPressEnter - A callback to call when hitting the Enter key. This overrides
 * the default "click" behavior"
 * @returns highlighted element index or null if none is selected
 */
export default function useKeyboardNavigation({
  isDropdownOpen,
  menuElement,
  closeDropdown,
  selectedOptionClassName,
  inputRef,
  resetDependencies = [],
  automaticallySelectFirstOption = false,
  onPressEnter,
}: Props): number | null {
  const [highlightedElementIndex, setHighlightedElementIndex] = useState<null | number>(
    getInitialIndex(automaticallySelectFirstOption),
  );
  const prevHighlightedElementIndex = useRef<null | number>(null);

  const getOptionsAmount = () => menuElement.current?.querySelectorAll(
    `.${KEYBOARD_NAVIGATION_OPTION_CLASS_NAME}`,
  )?.length || 0;

  const scrollToOption = (index: number) => {
    const element = getNthChild(index);
    if (element) {
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  };

  useKeystrokesListener({
    ArrowDown: (event) => {
      event?.preventDefault();
      const optionsAmount = getOptionsAmount();
      if (isDropdownOpen && optionsAmount > 0) {
        prevHighlightedElementIndex.current = highlightedElementIndex;
        setHighlightedElementIndex((prev) => {
          if (!prev) return MIN_INDEX;
          const next = (prev + 1) % (optionsAmount + MIN_INDEX);
          scrollToOption(next);
          return next === 0 ? optionsAmount : next;
        });
      }
    },
    ArrowUp: (event) => {
      event?.preventDefault();
      const optionsAmount = getOptionsAmount();
      if (isDropdownOpen && optionsAmount > 0) {
        prevHighlightedElementIndex.current = highlightedElementIndex;
        setHighlightedElementIndex((prev) => {
          if (!prev) return null;
          const next = prev - 1;
          scrollToOption(next);
          return next === 0 ? null : next;
        });
      }
    },
    Enter: () => {
      if (highlightedElementIndex) {
        const element = getNthChild(highlightedElementIndex);
        if (onPressEnter) {
          onPressEnter(highlightedElementIndex - 1);
        } else if (element) {
          (element as HTMLElement).click();
        }
        closeDropdown();
      }
    },
    Escape: () => {
      closeDropdown();
    },
  }, inputRef);

  const getNthChild = (index: number) =>
    menuElement.current
      ?.querySelectorAll(`.${KEYBOARD_NAVIGATION_OPTION_CLASS_NAME}`)
      ?.[index - 1];

  useEffect(() => {
    if (prevHighlightedElementIndex.current) {
      const previouslySelectedElement = getNthChild(prevHighlightedElementIndex.current);
      previouslySelectedElement?.classList.remove(selectedOptionClassName);
    }
    if (highlightedElementIndex) {
      const newlySelectedElement = getNthChild(highlightedElementIndex);
      newlySelectedElement?.classList.add(selectedOptionClassName);
    }
  }, [highlightedElementIndex]);

  useEffect(() => {
    setHighlightedElementIndex(null);
    setTimeout(() => { // New elements are rendering, so we need to select the new first element
      setHighlightedElementIndex(getInitialIndex(automaticallySelectFirstOption));
    }, 0);
    prevHighlightedElementIndex.current = null;
  }, [isDropdownOpen, ...resetDependencies]);

  return highlightedElementIndex;
}
