import { formatDistanceStrict } from 'date-fns';

import { MetricValueResponse } from 'global/api/controller/MetricController';
import { AppType } from 'global/lists/apps';
import { getSignificanceLabel } from 'global/lists/significanceColors';
import UsageContext from 'global/lists/UsageContext';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import InteractionsTableColumn
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/InteractionsTableColumn';
import {
  EMPTY_METRIC,
  numericMetricFormatter,
  percentMetricFormatter,
  timeInMillisToDurationFormatter,
  timeSinceMetricFormatter,
} from 'utils/MetricUtils';

enum Metric {
  // Interaction metrics
  POTENTIAL_SIGNIFICANCE = 'POTENTIAL_SIGNIFICANCE',
  TOTAL_VOLUME = 'TOTAL_VOLUME',
  RELATIVE_VOLUME = 'RELATIVE_VOLUME',
  LAST_INTERACTION = 'LAST_INTERACTION',
  TOTAL_PEOPLE = 'TOTAL_PEOPLE',
  TOTAL_CONTACTS = 'TOTAL_CONTACTS',
  TOTAL_PARTICIPANTS = 'TOTAL_PARTICIPANTS',
  TOTAL_INTERACTIONS = 'TOTAL_INTERACTIONS',
  LIFETIME = 'LIFETIME',

  // Person metrics
  PERSON_LAST_INTERACTION = 'PERSON_LAST_INTERACTION',
  PERSON_TIME_IN_MEETINGS_LAST_7_DAYS = 'PERSON_TIME_IN_MEETINGS_LAST_7_DAYS',
  PERSON_TIME_IN_MEETINGS_LAST_28_DAYS = 'PERSON_TIME_IN_MEETINGS_LAST_28_DAYS',
  PERSON_TIME_IN_MEETINGS_LAST_3_MONTHS = 'PERSON_TIME_IN_MEETINGS_LAST_3_MONTHS',
  PERSON_TIME_IN_MEETINGS_LAST_6_MONTHS = 'PERSON_TIME_IN_MEETINGS_LAST_6_MONTHS',
  PERSON_TIME_IN_MEETINGS_LAST_9_MONTHS = 'PERSON_TIME_IN_MEETINGS_LAST_9_MONTHS',
  PERSON_TIME_IN_MEETINGS_LAST_12_MONTHS = 'PERSON_TIME_IN_MEETINGS_LAST_12_MONTHS',
  PERSON_TOTAL_INTERACTIONS = 'PERSON_TOTAL_INTERACTIONS',
  PERSON_TOTAL_VOLUME = 'PERSON_TOTAL_VOLUME',

  // Organization metrics
  ORGANIZATION_LAST_MEETING = 'ORGANIZATION_LAST_MEETING',
  ORGANIZATION_LAST_CONVERSATION = 'ORGANIZATION_LAST_CONVERSATION',
  ORGANIZATION_LAST_ENGAGEMENT = 'ORGANIZATION_LAST_ENGAGEMENT',
  ORGANIZATION_TOTAL_CONVERSATIONS = 'ORGANIZATION_TOTAL_CONVERSATIONS',
  ORGANIZATION_TOTAL_MEETINGS = 'ORGANIZATION_TOTAL_MEETINGS',
  ORGANIZATION_TOTAL_ENGAGEMENTS = 'ORGANIZATION_TOTAL_ENGAGEMENTS',
  ORGANIZATION_TIME_IN_MEETINGS_LAST_SIX_MONTHS = 'ORGANIZATION_TIME_IN_MEETINGS_LAST_SIX_MONTHS',
  ORGANIZATION_TIME_IN_MEETINGS_LAST_NINE_MONTHS = 'ORGANIZATION_TIME_IN_MEETINGS_LAST_NINE_MONTHS',
  ORGANIZATION_TIME_IN_MEETINGS_LAST_TWELVE_MONTHS = 'ORGANIZATION_TIME_IN_MEETINGS_LAST_TWELVE_MONTHS',
  ORGANIZATION_TIME_IN_MEETINGS_LAST_THREE_MONTHS = 'ORGANIZATION_TIME_IN_MEETINGS_LAST_THREE_MONTHS',
  ORGANIZATION_CONVERSATION_FREQUENCY = 'ORGANIZATION_CONVERSATION_FREQUENCY',
  ORGANIZATION_MEETING_FREQUENCY = 'ORGANIZATION_MEETING_FREQUENCY',
  ORGANIZATION_ENGAGEMENT_FREQUENCY = 'ORGANIZATION_ENGAGEMENT_FREQUENCY',
  ORGANIZATION_TOTAL_MENTIONS = 'ORGANIZATION_TOTAL_MENTIONS',
  ORGANIZATION_TOTAL_PEOPLE = 'ORGANIZATION_TOTAL_PEOPLE',
  ORGANIZATION_TOTAL_CONTACTS = 'ORGANIZATION_TOTAL_CONTACTS',
  ORGANIZATION_TIME_SINCE_LAST_MENTION = 'ORGANIZATION_TIME_SINCE_LAST_MENTION',
  ORGANIZATION_TIME_SINCE_FIRST_MENTION = 'ORGANIZATION_TIME_SINCE_FIRST_MENTION',
  ORGANIZATION_TOTAL_OUTBOUND_INTERACTIONS = 'ORGANIZATION_TOTAL_OUTBOUND_INTERACTIONS',
  ORGANIZATION_TOTAL_INBOUND_INTERACTIONS = 'ORGANIZATION_TOTAL_INBOUND_INTERACTIONS',
  ORGANIZATION_TOTAL_ENGAGEMENTS_VOLUME = 'ORGANIZATION_TOTAL_ENGAGEMENTS_VOLUME',
  ORGANIZATION_TOTAL_MENTIONS_VOLUME = 'ORGANIZATION_TOTAL_MENTIONS_VOLUME',

  // Tag metrics
  LAST_TAG_INTERACTION = 'LAST_TAG_INTERACTION',
  TOTAL_TAG_INTERACTIONS = 'TOTAL_TAG_INTERACTIONS',
  TOTAL_TAG_CONVERSATIONS = 'TOTAL_TAG_CONVERSATIONS',
  TOTAL_TAG_MEETINGS = 'TOTAL_TAG_MEETINGS',
  RELATIVE_TAG_VOLUME_LAST_3_MONTHS = 'RELATIVE_TAG_VOLUME_LAST_3_MONTHS'
}

enum MetricUnits {
  CLASSIFICATION = 'Classification',
  VOLUME_UNITS = 'Volume Units',
  DAYS = 'Days',
  HOURS = 'Hours',
  PEOPLE = 'People',
  INTERACTIONS = 'Interactions',
  PERCENTS = 'Percents',
}

interface MetricConfig {
  id: Metric;
  context: UsageContext;
  units: MetricUnits;
  getStringValue: (rawValue: MetricValueResponse) => string;
  interactionsTableColumn?: InteractionsTableColumn;
  interactionsTableLimit?: number;
  appType?: AppType;
  name: string;
  displayName?: string;
  directionality: Directionality | null;
}

const MetricToMetricConfig: Record<Metric, MetricConfig> = Object.freeze({
  [Metric.POTENTIAL_SIGNIFICANCE]: {
    id: Metric.POTENTIAL_SIGNIFICANCE,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.CLASSIFICATION,
    getStringValue: ({ value }) => (value ? getSignificanceLabel(value) : EMPTY_METRIC),
    interactionsTableColumn: InteractionsTableColumn.SIGNIFICANCE,
    name: 'Potential Significance',
    directionality: null,
  },
  [Metric.TOTAL_VOLUME]: {
    id: Metric.TOTAL_VOLUME,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.VOLUME_UNITS,
    getStringValue: numericMetricFormatter,
    interactionsTableColumn: InteractionsTableColumn.VOLUME,
    name: 'Total Volume',
    directionality: null,
  },
  [Metric.RELATIVE_VOLUME]: {
    id: Metric.RELATIVE_VOLUME,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.PERCENTS,
    getStringValue: numericMetricFormatter,
    interactionsTableColumn: InteractionsTableColumn.VOLUME,
    name: 'Relative Volume',
    directionality: null,
  },
  [Metric.LAST_INTERACTION]: {
    id: Metric.LAST_INTERACTION,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.DAYS,
    getStringValue: timeSinceMetricFormatter,
    interactionsTableColumn: InteractionsTableColumn.UPDATED_AT,
    interactionsTableLimit: 1,
    name: 'Time Since Last Interaction',
    displayName: 'Last Interaction',
    directionality: null,
  },
  [Metric.TOTAL_PEOPLE]: {
    id: Metric.TOTAL_PEOPLE,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.PEOPLE,
    getStringValue: numericMetricFormatter,
    name: 'Total People',
    directionality: null,
  },
  [Metric.TOTAL_CONTACTS]: {
    id: Metric.TOTAL_CONTACTS,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.PEOPLE,
    getStringValue: ({ value }) => value?.toFixed(0) || '-',
    name: 'Total Contacts',
    directionality: null,
  },
  [Metric.TOTAL_PARTICIPANTS]: {
    id: Metric.TOTAL_PARTICIPANTS,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.PEOPLE,
    getStringValue: numericMetricFormatter,
    name: 'Total Participants',
    directionality: null,
  },
  [Metric.TOTAL_INTERACTIONS]: {
    id: Metric.TOTAL_INTERACTIONS,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    name: 'Total Interactions',
    directionality: null,
  },
  [Metric.ORGANIZATION_LAST_ENGAGEMENT]: {
    id: Metric.ORGANIZATION_LAST_ENGAGEMENT,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: timeSinceMetricFormatter,
    interactionsTableColumn: InteractionsTableColumn.UPDATED_AT,
    interactionsTableLimit: 1,
    name: 'Time Since Last Engagement',
    displayName: 'Last Engagement',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.ORGANIZATION_TOTAL_ENGAGEMENTS]: {
    id: Metric.ORGANIZATION_TOTAL_ENGAGEMENTS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    name: 'Total Engagements',
    displayName: 'Total Engagements (Last 3 Months)',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.LIFETIME]: {
    id: Metric.LIFETIME,
    context: UsageContext.INTERACTIONS,
    units: MetricUnits.DAYS,
    getStringValue: numericMetricFormatter,
    name: 'Lifetime',
    directionality: null,
  },
  [Metric.ORGANIZATION_ENGAGEMENT_FREQUENCY]: {
    id: Metric.ORGANIZATION_ENGAGEMENT_FREQUENCY,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: ({ value }) => (value ? formatDistanceStrict(0, value) : EMPTY_METRIC),
    name: 'Average Engagement Frequency',
    displayName: 'Engagement Frequency (Last 3 Months)',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.ORGANIZATION_TOTAL_MENTIONS]: {
    id: Metric.ORGANIZATION_TOTAL_MENTIONS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    name: 'Total Mentions',
    displayName: 'Total Mentions (Last 3 Months)',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.ORGANIZATION_TIME_SINCE_LAST_MENTION]: {
    id: Metric.ORGANIZATION_TIME_SINCE_LAST_MENTION,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: timeSinceMetricFormatter,
    name: 'Time Since Last Mention',
    displayName: 'Last Mention',
    directionality: Directionality.INTERNAL,
  },
  [Metric.ORGANIZATION_TIME_SINCE_FIRST_MENTION]: {
    id: Metric.ORGANIZATION_TIME_SINCE_FIRST_MENTION,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: timeSinceMetricFormatter,
    name: 'Time Since First Mention',
    displayName: 'First Mention',
    directionality: Directionality.INTERNAL,
  },
  [Metric.ORGANIZATION_TOTAL_OUTBOUND_INTERACTIONS]: {
    id: Metric.ORGANIZATION_TOTAL_OUTBOUND_INTERACTIONS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    name: 'Total Outbound Interactions',
    displayName: 'Total Outbound Interactions (Last 3 Months)',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.ORGANIZATION_TOTAL_INBOUND_INTERACTIONS]: {
    id: Metric.ORGANIZATION_TOTAL_INBOUND_INTERACTIONS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    name: 'Total Inbound Interactions',
    displayName: 'Total Inbound Interactions (Last 3 Months)',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.ORGANIZATION_TOTAL_ENGAGEMENTS_VOLUME]: {
    id: Metric.ORGANIZATION_TOTAL_ENGAGEMENTS_VOLUME,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.VOLUME_UNITS,
    getStringValue: numericMetricFormatter,
    name: 'Total Engagements Volume',
    displayName: 'Total Engagements Volume (Last 3 Months)',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.ORGANIZATION_TOTAL_MENTIONS_VOLUME]: {
    id: Metric.ORGANIZATION_TOTAL_MENTIONS_VOLUME,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.VOLUME_UNITS,
    getStringValue: numericMetricFormatter,
    name: 'Total Mentions Volume',
    displayName: 'Total Mentions Volume (Last 3 Months)',
    directionality: Directionality.INTERNAL,
  },
  [Metric.ORGANIZATION_TOTAL_PEOPLE]: {
    id: Metric.ORGANIZATION_TOTAL_PEOPLE,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.PEOPLE,
    getStringValue: numericMetricFormatter,
    name: 'Total People',
    displayName: 'Total People (Last 3 Months)',
    directionality: null,
  },
  [Metric.ORGANIZATION_TOTAL_CONTACTS]: {
    id: Metric.ORGANIZATION_TOTAL_CONTACTS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.PEOPLE,
    getStringValue: numericMetricFormatter,
    name: 'Total Contacts',
    displayName: 'Total Contacts (Last 3 Months)',
    directionality: null,
  },
  [Metric.PERSON_LAST_INTERACTION]: {
    id: Metric.PERSON_LAST_INTERACTION,
    context: UsageContext.PEOPLE,
    units: MetricUnits.DAYS,
    getStringValue: timeSinceMetricFormatter,
    name: 'Last Person Interaction',
    directionality: null,
  },
  [Metric.PERSON_TIME_IN_MEETINGS_LAST_7_DAYS]: {
    id: Metric.PERSON_TIME_IN_MEETINGS_LAST_7_DAYS,
    context: UsageContext.PEOPLE,
    units: MetricUnits.HOURS,
    getStringValue: (dailyTimeInMeetings) =>
      timeInMillisToDurationFormatter(dailyTimeInMeetings, true),
    appType: AppType.MEETINGS,
    name: 'Total Time in Meetings (Last 7 days)',
    directionality: null,
  },
  [Metric.PERSON_TIME_IN_MEETINGS_LAST_28_DAYS]: {
    id: Metric.PERSON_TIME_IN_MEETINGS_LAST_28_DAYS,
    context: UsageContext.PEOPLE,
    units: MetricUnits.HOURS,
    getStringValue: (dailyTimeInMeetings) =>
      timeInMillisToDurationFormatter(dailyTimeInMeetings, true),
    appType: AppType.MEETINGS,
    name: 'Total Time in Meetings (Last 28 days)',
    directionality: null,
  },
  [Metric.PERSON_TIME_IN_MEETINGS_LAST_3_MONTHS]: {
    id: Metric.PERSON_TIME_IN_MEETINGS_LAST_3_MONTHS,
    context: UsageContext.PEOPLE,
    units: MetricUnits.HOURS,
    getStringValue: (dailyTimeInMeetings) =>
      timeInMillisToDurationFormatter(dailyTimeInMeetings, true),
    appType: AppType.MEETINGS,
    name: 'Total Time in Meetings (Last 3 Months)',
    directionality: null,
  },
  [Metric.PERSON_TIME_IN_MEETINGS_LAST_6_MONTHS]: {
    id: Metric.PERSON_TIME_IN_MEETINGS_LAST_6_MONTHS,
    context: UsageContext.PEOPLE,
    units: MetricUnits.HOURS,
    getStringValue: (dailyTimeInMeetings) =>
      timeInMillisToDurationFormatter(dailyTimeInMeetings, true),
    appType: AppType.MEETINGS,
    name: 'Total Time in Meetings (Last 6 Months)',
    directionality: null,
  },
  [Metric.PERSON_TIME_IN_MEETINGS_LAST_9_MONTHS]: {
    id: Metric.PERSON_TIME_IN_MEETINGS_LAST_9_MONTHS,
    context: UsageContext.PEOPLE,
    units: MetricUnits.HOURS,
    getStringValue: (dailyTimeInMeetings) =>
      timeInMillisToDurationFormatter(dailyTimeInMeetings, true),
    appType: AppType.MEETINGS,
    name: 'Total Time in Meetings (Last 9 Months)',
    directionality: null,
  },
  [Metric.PERSON_TIME_IN_MEETINGS_LAST_12_MONTHS]: {
    id: Metric.PERSON_TIME_IN_MEETINGS_LAST_12_MONTHS,
    context: UsageContext.PEOPLE,
    units: MetricUnits.HOURS,
    getStringValue: (dailyTimeInMeetings) =>
      timeInMillisToDurationFormatter(dailyTimeInMeetings, true),
    appType: AppType.MEETINGS,
    name: 'Total Time in Meetings (Last 12 Months)',
    directionality: null,
  },
  [Metric.PERSON_TOTAL_INTERACTIONS]: {
    id: Metric.PERSON_TOTAL_INTERACTIONS,
    context: UsageContext.PEOPLE,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    name: 'Total Person Engagements',
    displayName: 'Total Engagements (Last 3 Months)',
    directionality: null,
  },
  [Metric.PERSON_TOTAL_VOLUME]: {
    id: Metric.PERSON_TOTAL_VOLUME,
    context: UsageContext.PEOPLE,
    units: MetricUnits.VOLUME_UNITS,
    getStringValue: numericMetricFormatter,
    name: 'Total Person Volume',
    displayName: 'Total Volume (Last 3 Months)',
    directionality: null,
  },
  [Metric.ORGANIZATION_LAST_MEETING]: {
    id: Metric.ORGANIZATION_LAST_MEETING,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: timeSinceMetricFormatter,
    interactionsTableColumn: InteractionsTableColumn.UPDATED_AT,
    interactionsTableLimit: 1,
    appType: AppType.MEETINGS,
    name: 'Last Meeting',
    directionality: null,
  },
  [Metric.ORGANIZATION_LAST_CONVERSATION]: {
    id: Metric.ORGANIZATION_LAST_CONVERSATION,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: timeSinceMetricFormatter,
    interactionsTableColumn: InteractionsTableColumn.UPDATED_AT,
    interactionsTableLimit: 1,
    appType: AppType.CONVERSATIONS,
    name: 'Last Conversation',
    directionality: null,
  },
  [Metric.ORGANIZATION_TOTAL_CONVERSATIONS]: {
    id: Metric.ORGANIZATION_TOTAL_CONVERSATIONS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    appType: AppType.CONVERSATIONS,
    name: 'Total Conversations',
    displayName: 'Total Conversations (Last 3 Months)',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.ORGANIZATION_TOTAL_MEETINGS]: {
    id: Metric.ORGANIZATION_TOTAL_MEETINGS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    appType: AppType.MEETINGS,
    name: 'Total Meetings',
    displayName: 'Total Meetings (Last 3 Months)',
    directionality: Directionality.EXTERNAL,
  },
  [Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_SIX_MONTHS]: {
    id: Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_SIX_MONTHS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: (metric) => timeInMillisToDurationFormatter(metric, true),
    appType: AppType.MEETINGS,
    name: 'Time in Meetings (Last 6 Months)',
    directionality: null,
  },
  [Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_NINE_MONTHS]: {
    id: Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_NINE_MONTHS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: (metric) => timeInMillisToDurationFormatter(metric, true),
    appType: AppType.MEETINGS,
    name: 'Time in Meetings (Last 9 Months)',
    directionality: null,
  },
  [Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_TWELVE_MONTHS]: {
    id: Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_TWELVE_MONTHS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: (metric) => timeInMillisToDurationFormatter(metric, true),
    appType: AppType.MEETINGS,
    name: 'Time in Meetings (Last 12 Months)',
    directionality: null,
  },
  [Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_THREE_MONTHS]: {
    id: Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_THREE_MONTHS,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: (metric) => timeInMillisToDurationFormatter(metric, true),
    appType: AppType.MEETINGS,
    name: 'Time in Meetings (Last 3 Months)',
    directionality: null,
  },
  [Metric.ORGANIZATION_CONVERSATION_FREQUENCY]: {
    id: Metric.ORGANIZATION_CONVERSATION_FREQUENCY,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: ({ value }) => (value ? formatDistanceStrict(0, value) : EMPTY_METRIC),
    appType: AppType.CONVERSATIONS,
    name: 'Conversations Frequency',
    displayName: 'Conversations Frequency (Last 3 Months)',
    directionality: null,
  },
  [Metric.ORGANIZATION_MEETING_FREQUENCY]: {
    id: Metric.ORGANIZATION_MEETING_FREQUENCY,
    context: UsageContext.ORGANIZATIONS,
    units: MetricUnits.DAYS,
    getStringValue: ({ value }) => (value ? formatDistanceStrict(0, value) : EMPTY_METRIC),
    appType: AppType.MEETINGS,
    name: 'Meetings Frequency',
    displayName: 'Meetings Frequency (Last 3 Months)',
    directionality: null,
  },
  [Metric.LAST_TAG_INTERACTION]: {
    id: Metric.LAST_TAG_INTERACTION,
    context: UsageContext.TAGS,
    units: MetricUnits.DAYS,
    getStringValue: timeSinceMetricFormatter,
    name: 'Last Tag Activity',
    displayName: 'Last Activity',
    directionality: null,
  },
  [Metric.TOTAL_TAG_INTERACTIONS]: {
    id: Metric.TOTAL_TAG_INTERACTIONS,
    context: UsageContext.TAGS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    name: 'Total Tag Interactions',
    displayName: 'Total Interactions (Last 3 Months)',
    directionality: null,
  },
  [Metric.TOTAL_TAG_CONVERSATIONS]: {
    id: Metric.TOTAL_TAG_CONVERSATIONS,
    context: UsageContext.TAGS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    appType: AppType.CONVERSATIONS,
    name: 'Total Tag Conversations',
    displayName: 'Total Conversations (Last 3 Months)',
    directionality: null,
  },
  [Metric.TOTAL_TAG_MEETINGS]: {
    id: Metric.TOTAL_TAG_MEETINGS,
    context: UsageContext.TAGS,
    units: MetricUnits.INTERACTIONS,
    getStringValue: numericMetricFormatter,
    appType: AppType.MEETINGS,
    name: 'Total Tag Meetings',
    displayName: 'Total Meetings (Last 3 Months)',
    directionality: null,
  },
  [Metric.RELATIVE_TAG_VOLUME_LAST_3_MONTHS]: {
    id: Metric.RELATIVE_TAG_VOLUME_LAST_3_MONTHS,
    context: UsageContext.TAGS,
    units: MetricUnits.PERCENTS,
    getStringValue: percentMetricFormatter,
    name: 'Relative Tag Volume',
    displayName: 'Relative Volume (Last 3 Months)',
    directionality: null,
  },
});

export function getMetricName(metric: Metric): string {
  return MetricToMetricConfig[metric].name;
}

export function getMetricDirectionality(metric: Metric): Directionality | null {
  return MetricToMetricConfig[metric].directionality;
}

export function getMetricDisplayName(metric: Metric): string {
  const metricConfig = MetricToMetricConfig[metric];
  return metricConfig.displayName ?? metricConfig.name;
}

export function getMetricConfig(metric: Metric): MetricConfig {
  return MetricToMetricConfig[metric];
}

export default Metric;
