import classNames from 'classnames';
import React, { useMemo } from 'react';

import App, { hasChannels } from 'global/lists/apps';
import { Atom } from 'model/Atom';
import { formatDateRelativelyToNow } from 'utils/DateUtils';

import { ReactComponent as LockIcon } from 'assets/img/icon/lock.svg';

import style from 'common-ui-components/InteractionTitle/InteractionSubtitle/style.module.scss';

interface Props {
  item: Atom;
  showUpdatedAt?: boolean;
}

const BULLET = '\u2022';

export default function InteractionSubtitle({
  item,
  showUpdatedAt = false,
}: Props) {
  const {
    id, channelName, isPublic, appId, end,
  } = item;

  const shouldShowSubtitle = hasChannels(appId) || showUpdatedAt;
  const shouldRenderSeparator = hasChannels(appId) && Boolean(channelName) && showUpdatedAt;

  const channel = useMemo(() => {
    if (!hasChannels(appId)) return null;

    if (appId === App.SLACK && channelName) {
      return (
        <div className={style.slackChannel}>
          {!isPublic && <LockIcon className={style.lockIcon} />}
          {`${isPublic ? '#' : ' '}${channelName}`}
        </div>
      );
    }
    if (appId === App.ATLASSIAN_JIRA) return id;

    return channelName;
  }, [item]);

  const updatedDate = `Updated ${formatDateRelativelyToNow(end)}`;

  if (!shouldShowSubtitle) return null;

  return (
    <div className={style.subtitle}>
      {channel && (
        <div className={classNames(style.truncatedText, style.channel)}>
          {channel}
        </div>
      )}
      {shouldRenderSeparator && BULLET}
      {showUpdatedAt && (
        <div className={classNames(style.truncatedText, style.date)}>
          {updatedDate}
        </div>
      )}
    </div>
  );
}
