import { format } from 'date-fns';
import React from 'react';

import { getMetricConfig } from 'global/lists/Metric';
import EmptyMetricValue from 'screens/platform/directory/components/metricValue/EmptyMetricValue';
import MetricValueProps from 'screens/platform/directory/components/metricValue/MetricValueProps';
import DateUtils from 'utils/DateUtils';

import style from 'screens/platform/directory/components/metricValue/LastEngagementMetricValue/style.module.scss';

export default function LastEngagementMetricValue({ metricValue, metric }: MetricValueProps) {
  if (!metricValue) return <EmptyMetricValue />;
  const metricConfig = getMetricConfig(metric);

  const interactionEndDate = metricValue.interaction?.end;
  if (!interactionEndDate) return <EmptyMetricValue />;

  const timeAgoLabel = metricConfig.getStringValue(metricValue);
  const dateLabel = format(
    new Date(interactionEndDate),
    DateUtils.DateFormat.WEEKDAY_MONTH_DAY_TH,
  );

  return (
    <div>
      <div>{timeAgoLabel}</div>
      <div className={style.dateSubtitle}>{dateLabel}</div>
    </div>
  );
}
