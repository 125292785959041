import { useEffect, useMemo } from 'react';

import MetricController from 'global/api/controller/MetricController';
import Metric from 'global/lists/Metric';
import { EnrichedOrganization } from 'model/Organization';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import OrganizationWithMetrics, { MetricValues } from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

const emptyMetricValue = {
  value: null,
  previousPeriodValue: null,
  interaction: null,
};

export default function useOrganizationMetricsPopulator(
  metrics: Metric[],
  organization: EnrichedOrganization,
): OrganizationWithMetrics {
  const { tenant } = useTenantContext();

  const [
    metricToValueByOrganization,
    setMetricToValueByOrganization,
  ] = useMountedState<MetricValues>({});

  useEffect(() => {
    const fetchMetricValues = async (metric: Metric) => {
      try {
        const res = await MetricController.getMetricValuesByOrganizations(
          tenant.id,
          metric,
          [organization.id],
        );
        setMetricToValueByOrganization((prev) => ({
          ...prev,
          [metric]: res?.data[organization.id] ?? emptyMetricValue,
        }));
      } catch (error) {
        DebuggerConsole.error('Failed to load metric values for organization');
      }
    };
    metrics.forEach(fetchMetricValues);
  }, [metrics.sort().join(), organization, setMetricToValueByOrganization]);

  return useMemo<OrganizationWithMetrics>(() => ({
    ...organization,
    metricValues: metricToValueByOrganization,
  }), [organization, metricToValueByOrganization]);
}
